body {
	font-family: "Nunito Sans", sans-serif;
	margin: 0;
	padding: 0;
	font-size: 14px;
}

body.day-theme {
	background-color: #f0f0f5; /* Light background for daytime */
	color: #000; /* Dark text */
	transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

body.night-theme {
	background-color: #1c1c28; /* Dark background for nighttime */
	color: #fff; /* Light text */
	transition: background-color 0.3s ease, color 0.3s ease; /* Smooth transition */
}

input {
	font-family: "Nunito Sans", sans-serif;
}

.header {
	display: flex;
	align-items: center; /* Align items vertically to the center */
	justify-content: space-between; /* Place elements on opposite ends (left and right) */
	padding: 0.5em 2em;
	width: 100%; /* Ensure the header takes full width */
	box-sizing: border-box; /* Include padding in the width */
}

.header-left {
	display: flex;
	align-items: center; /* Align logo and title in the middle vertically */
	gap: 0.5rem; /* Space between logo and title */
}

.logo {
	height: 40px; /* Set a fixed height for the logo */
	width: auto; /* Maintain aspect ratio */
	cursor: pointer;
}

.tot-title {
	font-weight: bold;
	font-size: large;
	margin-right: 10px;
	cursor: pointer;
	white-space: nowrap;
}

.time-type {
	cursor: pointer;
}

.time-type:hover {
	color: #ffa91f;
}

.header-right {
	display: flex;
	align-items: center; /* Align the volume icon vertically in the middle */
	position: relative;
}

.tooltip {
	position: relative;
	cursor: pointer;
}

.tooltip::after {
	content: attr(data-tooltip);
	position: absolute;
	bottom: -35px; /* Adjust this based on the height of the tooltip */
	left: 50%;
	transform: translateX(-50%);
	padding: 5px 10px;
	background-color: #333;
	color: #fff;
	border-radius: 4px;
	white-space: nowrap;
	opacity: 0;
	visibility: hidden;
	font-size: 12px;
	transition: opacity 0.3s, visibility 0.3s;
}

/* Display tooltip on hover */
.tooltip:hover::after {
	opacity: 1;
	visibility: visible;
}

.time-display {
	width: 100%;
	margin-top: 1em;
	text-align: center;
	padding: 5em 0em;
}

.time {
	font-size: 5rem;
	margin: 0 !important;
	font-family: "Montserrat", sans-serif;
	font-weight: bold;
	letter-spacing: 0.1rem;
	width: 100%;
	cursor: pointer;
}

.location {
	display: flex;
	align-items: center; /* Align items vertically in the middle */
	justify-content: space-between; /* Place first div on the left and second div on the right */
	width: 100%;
	max-width: 800px;
	margin: 1em auto;
	padding: 0 2em;
	box-sizing: border-box; /* Include padding in the element's width */
}

.location div {
	font-size: 1.5rem;
	font-weight: bold;
	color: #555;
}

.location-info {
	display: flex;
	align-items: center; /* Align text and icon in the middle vertically */
	gap: 0.5rem; /* Space between the text and icon */
}

.timezones {
	display: grid;
	grid-template-columns: repeat(8, 1fr); /* Create 8 evenly spaced columns */
	width: 100%;
	box-sizing: border-box;
}

.timezone-display {
	text-align: center;
	background-color: #f5f5f5;
	padding: 1rem;
	border: 1px solid #555;
}

.timezone-display div:first-child {
	font-weight: bold;
}

.timezone-display div:nth-child(2) {
	font-size: x-large;
	margin: 5px 0px;
	font-weight: bold;
}

.header-right span {
	margin: 0px 10px;
}

/* Responsive: On small screens */
@media (max-width: 768px) {
	.time-display .time {
		font-size: 3rem;
	}

	.location {
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	.timezones {
		grid-template-columns: repeat(
			2,
			1fr
		); /* Show 2 columns on smaller screens */
	}
}

/* Fullscreen mode styling */
.fullscreen .header-right,
.fullscreen .location,
.fullscreen .timezones {
	display: none;
}

.fullscreen .time-display {
	height: 100vh;
	justify-content: center;
	align-items: center;
}

.button-default {
	background-color: #fafbfc;
	border: 1px solid rgba(27, 31, 35, 0.15);
	border-radius: 6px;
	box-shadow: rgba(27, 31, 35, 0.04) 0 1px 0,
		rgba(255, 255, 255, 0.25) 0 1px 0 inset;
	box-sizing: border-box;
	color: #24292e;
	cursor: pointer;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	gap: 8px;
	font-size: 16px;
	padding: 6px 16px;
	transition: background-color 0.2s cubic-bezier(0.3, 0, 0.5, 1);
	user-select: none;
	vertical-align: middle;
	white-space: nowrap;
	font-family: "Nunito Sans", sans-serif;
	letter-spacing: 0px;
	margin: 0 5px;
}

.button-default:hover {
	background-color: #f3f4f6;
	text-decoration: none;
	transition-duration: 0.1s;
}

.sw-time {
	display: inline-block;
	width: 250px;
	text-align: center;
	font-family: "Montserrat", sans-serif !important;
}

.time h1 {
	width: 100%;
	font-family: "Montserrat", sans-serif !important;
}

.laps {
	padding-bottom: 8em;
	margin-top: 1em;
}

.laps-header {
	display: flex;
	align-items: center; /* Vertically center icon and text */
	justify-content: center; /* Horizontally center icon and text */
	font-weight: bold; /* Make the text bold */
	gap: 8px; /* Add some space between the icon and text */
	text-align: center; /* Center-align text inside the flex container */
	margin-bottom: 1em;
}

.laps-time-container {
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: 0 auto;
	text-align: center;
}

.laps-time {
	margin: 5px;
}

.icon-text {
	display: flex;
	align-items: center;
	gap: 5px;
	cursor: pointer;
	color: red;
	margin-top: 1em;
}

.custom-timer-input {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-top: 20px;
	margin-bottom: 10px;
}

.custom-timer-input input[type="number"] {
	width: 60px;
	text-align: center;
	font-size: 16px;
	border: 1px solid rgb(218, 218, 218);
	border-radius: 6px;
	padding: 7px;
}

.custom-timer-input .time-separator {
	font-size: 18px;
	margin: 0 5px;
}

.timer-controls {
	margin-bottom: 10px;
}

.alert-section {
	margin: 20px auto;
	text-align: center;
}

.alert-section input[type="number"] {
	width: 60px;
	text-align: center;
	font-size: 16px;
	border: 1px solid rgb(218, 218, 218);
	border-radius: 6px;
	padding: 7px;
	margin-right: 10px;
}

.alert-section p {
	margin-bottom: 10px;
}

.alert-input-container {
	display: flex;
	align-items: center;
	justify-content: center;
	gap: 10px;
	margin-top: 20px;
}

.alert-input-container span {
	font-size: 16px;
	font-weight: bold;
}

.alert-input-container input[type="number"] {
	width: 60px;
	text-align: center;
	font-size: 16px;
	border: 1px solid #dadada;
	border-radius: 6px;
	padding: 7px;
	margin-right: 0px;
}

.button-default-small {
	font-size: small;
}

.button-action {
	background-color: #fcc060;
}

.button-action:hover {
	background-color: #fcc060;
}

.alert-message-container {
	display: flex;
	align-items: center; /* Vertically center the items */
	justify-content: center; /* Horizontally center the items */
	gap: 10px; /* Add space between items */
	margin-top: 20px; /* Add top margin if needed */
}

.alert-message-container span {
	font-size: 16px; /* Adjust font size */
}

.alert-message-container .cancel-alert {
	display: flex;
	align-items: center; /* Center icon and text vertically */
	cursor: pointer; /* Change cursor to pointer on hover */
	color: #ff0000; /* Optional: Set text color to red */
	gap: 5px; /* Space between icon and text */
}

.alert-message-container .cancel-alert:hover {
	text-decoration: underline; /* Optional: Underline text on hover */
}

.today {
	background-color: #fcc060;
	padding: 3px 10px;
	border-radius: 8px;
	font-weight: bold;
}

/* Container for location and sunrise/sunset info */
.info-container {
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	max-width: 800px;
	margin: 20px auto 0; /* Top margin and center alignment */
	margin-bottom: 1em;
}

/* Location display on the left */
.location-display {
	display: flex;
	align-items: center;
}

.location-display span {
	margin-left: 5px; /* Space between icon and text */
}

.gmt-offset {
	margin-left: 10px;
}

/* Sunrise and sunset display on the right */
.sunrise-sunset {
	display: flex;
	align-items: center;
}

.sunrise-sunset .sunrise,
.sunrise-sunset .sunset {
	display: flex;
	align-items: center;
}

.sunrise-sunset .sunset {
	margin-left: 20px; /* Space between sunrise and sunset */
}

.sunrise-sunset span {
	margin-left: 5px; /* Space between icon and time */
}

/* Adjust icon size and spacing */
.location-display svg,
.sunrise-sunset svg {
	width: 20px;
	height: 20px;
}

/* Ensure the main time is centered */
.time h1 {
	text-align: center;
	margin-bottom: 10px;
}

/* Center the entire time component */
.time {
	display: flex;
	flex-direction: column;
	align-items: center;
}

body.day-theme .timezone-display {
	background-color: #fafafa; /* Light background for daytime */
	color: #000; /* Dark text */
	border-color: #ccc; /* Light border */
}

body.night-theme .timezone-display {
	background-color: #2c2c3c; /* Dark background for nighttime */
	color: #f0f0f5; /* Light text */
	border-color: #555; /* Dark border */
}

/* Timezone grid responsiveness */
@media (max-width: 768px) {
	.timezones {
		grid-template-columns: repeat(
			2,
			1fr
		); /* Show 2 columns on smaller screens */
	}
}

/* General styling for the tooltip */
[data-tooltip-top] {
	position: relative;
	cursor: pointer;
}

/* Tooltip styling */
[data-tooltip-top]::after {
	content: attr(data-tooltip-top);
	position: absolute;
	top: -35px; /* Adjust this value to control the tooltip distance from the element */
	left: 50%;
	transform: translateX(-50%);
	background-color: #333;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 12px;
	white-space: nowrap;
	opacity: 0;
	visibility: hidden;
	transition: opacity 0.3s ease, visibility 0.3s ease;
	z-index: 10;
}

/* Display the tooltip on hover */
[data-tooltip-top]:hover::after {
	opacity: 1;
	visibility: visible;
}

.active-mode {
	color: #f7aa2e;
	font-size: small;
}

/* Fade-out effect */
.fade-out {
	animation: fadeOut 5s forwards; /* 3 seconds to fade out */
}

@keyframes fadeOut {
	0% {
		opacity: 1;
	}
	100% {
		opacity: 0;
	}
}

.timezone-header {
	margin-bottom: 3em;
}

.timezone-converter {
	padding: 1em 2em;
	font-family: "Nunito Sans", sans-serif;
	max-width: 1000px;
	margin: 0 auto;
}

.timezone-converter-container {
	display: flex;
	gap: 2em;
}

.left-panel {
	width: 40%;
}

.left-panel h1 {
	text-align: left;
}

.right-panel {
	width: 60%;
}

.search-bar {
	display: flex;
	flex-direction: column;
	margin-bottom: 20px;
}

.search-bar input {
	padding: 10px;
	font-size: 16px;
	border: 1px solid #ccc;
	border-radius: 5px;
}

.search-results {
	background-color: white;
	border: 1px solid #ccc;
	margin-top: 5px;
	border-radius: 5px;
	text-align: left;
}

.search-results div {
	padding: 10px;
	cursor: pointer;
}

.search-results div:hover {
	background-color: #f0f4f8;
}

.timezone-list {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.time-slider {
	display: flex;
	justify-content: space-between; /* Distributes space between slider and buttons */
	align-items: center; /* Centers vertically */
	margin: 20px 0;
}

.slider {
	flex-grow: 1; /* Makes the slider take up the available space */
	display: flex;
	flex-direction: column;
	align-items: center;
	padding-right: 20px;
}

.slider input[type="range"] {
	width: 100%;
}

.slider-labels {
	display: flex;
	justify-content: space-between;
	font-size: 12px;
	width: 100%; /* Ensure the labels take up the full width of the slider */
	margin-top: 5px;
}

.slider-date {
	text-align: center;
	margin-top: 10px;
	font-weight: bold;
	font-size: small;
}

.timezone-item .zone-name {
	font-weight: bold;
	width: 20%; /* Fixed width for place name */
	text-align: left;
}

.timezone-item .zone-date,
.timezone-item .zone-time,
.timezone-item .zone-offset {
	width: 20%; /* Fixed width for date, time, and timezone */
	text-align: left;
}

.timezone-item .zone-date {
	text-align: left;
	width: 30%; /* Width for date field */
}

.timezone-item .zone-time {
	text-align: left;
	width: 15%; /* Width for time field */
}

.timezone-item .zone-offset {
	text-align: left;
	width: 15%; /* Width for offset */
}

.timezone-item .remove-btn {
	width: 10%;
	text-align: right;
	padding-right: 10px;
}

/* Timezone List to ensure uniform look */
.timezone-list {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.slider-buttons {
	text-align: center;
}

.slider-controls span {
	cursor: pointer;
}

.slider-controls span:hover {
	color: #ffa91f;
}

/* Responsive Design: Adjust for smaller screens */
@media (max-width: 768px) {
	.timezone-item {
		flex-direction: column;
		align-items: flex-start;
	}

	.timezone-item .zone-name,
	.timezone-item .zone-date,
	.timezone-item .zone-time,
	.timezone-item .zone-offset,
	.remove-btn {
		width: 100%;
		text-align: left;
	}

	.slider-buttons {
		flex-direction: column; /* Stack buttons vertically on smaller screens */
	}
}

.timezone-item {
	display: flex;
	justify-content: space-between;
	align-items: center; /* Vertically center all children */
	padding: 10px;
	background-color: white;
	border: 1px solid #ccc;
	border-radius: 5px;
	transition: background-color 0.3s ease;
	margin-bottom: 10px;
}

.timezone-item .zone-name,
.timezone-item .zone-time,
.timezone-item .zone-offset {
	flex: 1; /* Ensure spans are evenly distributed */
	text-align: left; /* Align text to the left */
	padding: 0 10px; /* Add horizontal padding */
	display: flex;
	align-items: center; /* Vertically center the text inside each span */
}

.timezone-item .zone-name {
	font-weight: bold;
	font-size: 16px;
}

.timezone-item .remove-btn {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.timezone-item.day {
	background-color: white; /* Light background for daytime */
	color: #000; /* Dark text */
	transition: background-color 0.3s ease, color 0.3s ease;
}

.timezone-item.night {
	background-color: #1c1c28; /* Dark background for nighttime */
	color: #fff; /* Light text */
	transition: background-color 0.3s ease, color 0.3s ease;
}

.timezone-item.dragging {
	background-color: lightblue;
}

.timezone-list {
	display: flex;
	flex-direction: column;
	gap: 10px;
}

.slider-tooltip {
	position: absolute;
	top: -40px; /* Position it above the slider */
	background-color: #333;
	color: white;
	padding: 5px 10px;
	border-radius: 5px;
	font-size: 12px;
	transform: translateX(-50%); /* Center it horizontally */
	white-space: nowrap;
}

.search-results.day {
	background-color: #fff; /* Light background for day */
	color: #000; /* Dark text */
}

.search-results.night {
	background-color: #2c2c3c; /* Dark background for night */
	color: #fff; /* Light text */
}

.search-result-item {
	padding: 10px;
	cursor: pointer;
	border-bottom: 1px solid #ccc;
}

.search-result-item:hover {
	background-color: #f0f4f8;
}

.search-results.night .search-result-item:hover {
	background-color: #444 !important; /* Hover effect for night mode */
}

.zone-delete {
	cursor: pointer;
}

.zone-delete:hover {
	color: red;
}

.yearly-calendar {
	max-width: 1200px;
	margin: 0 auto;
	text-align: center;
	margin-bottom: 5em;
	margin-top: 2em;
}

.year-navigation {
	display: flex;
	justify-content: space-between; /* Push year to the left and buttons to the right */
	align-items: center;
	margin-bottom: 2em;
	padding: 0 20px;
}

.year-navigation .year {
	font-size: 1.5rem;
	font-weight: bold;
	display: flex;
	align-items: center; /* Align the calendar icon and year in the middle */
	gap: 10px; /* Space between the icon and the year */
}

.year-navigation .year-buttons {
	display: flex;
	align-items: center;
	gap: 10px; /* Space between the buttons */
}

.year-navigation .arrow-icon {
	cursor: pointer;
}

.calendar-grid {
	display: grid;
	grid-template-columns: repeat(3, 1fr);
	gap: 20px;
}

.month {
	border: 1px solid #ccc;
	padding: 10px;
	border-radius: 10px;
	background-color: #2c2c3c;
	color: #f0f0f5;
	box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
}

.month h3 {
	margin-bottom: 10px;
	color: #fff;
}

.days-of-week {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	margin-bottom: 10px;
}

.day-of-week {
	padding: 5px;
	font-weight: bold;
	text-align: center;
	background-color: #444;
	color: #fff;
}

.days {
	display: grid;
	grid-template-columns: repeat(7, 1fr);
	gap: 5px;
}

.day-cal,
.empty-day {
	width: 40px;
	height: 40px;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: #333;
	color: white;
	border-radius: 5px;
	position: relative; /* For positioning the tooltip */
	cursor: pointer;
}

.day-cal:hover {
	background-color: #444;
}

.empty-day {
	visibility: hidden;
}

.today-calendar {
	background-color: #fcc060;
	color: white;
}

.holiday {
	background-color: #ff6961;
	color: white;
}

/* Tooltip for holidays */
.tooltip-cal {
	display: none;
	position: absolute;
	bottom: 45px; /* Adjusts the position above the date */
	left: 50%;
	transform: translateX(-50%); /* Centers the tooltip horizontally */
	background-color: #333;
	color: #fff;
	padding: 5px 10px;
	border-radius: 4px;
	font-size: 12px;
	white-space: nowrap;
	z-index: 10;
}

.day-cal.holiday:hover .tooltip-cal {
	display: block;
}

@media (max-width: 768px) {
	.calendar-grid {
		grid-template-columns: repeat(2, 1fr);
	}
}

@media (max-width: 480px) {
	.calendar-grid {
		grid-template-columns: 1fr;
	}

	.day-cal,
	.empty-day {
		width: 30px;
		height: 30px;
	}
}

.footer {
	display: flex;
	position: fixed;
	bottom: 0;
	width: 100%;
	padding: 10px 20px;
	box-shadow: 0px -2px 5px rgba(0, 0, 0, 0.1);
	font-weight: bold;
	cursor: pointer;
	transition: background-color 0.3s ease, color 0.3s ease;
}

.footer-left,
.footer-center,
.footer-right {
	display: flex;
	align-items: center; /* Vertically center all items */
	justify-content: center; /* Align content horizontally inside */
	width: 33%;
}

.footer-left span,
.footer-right a {
	display: flex;
	align-items: center; /* Vertically center icons and text */
	gap: 5px; /* Space between icon and text */
}

.footer-right a {
	margin-left: 15px;
}

.footer-left span {
	margin-right: 15px; /* Adjust spacing between items */
}

.footer-center {
	flex-grow: 1; /* Let the center take available space */
	justify-content: center; /* Center the content */
}

.footer.day {
	background-color: #f0f0f0;
	color: #000;
}

.footer.night {
	background-color: #1c1c28;
	color: #fff;
}

.footer a {
	color: inherit;
	text-decoration: none;
}

/* Sidebar styles */
.sidebar {
	position: fixed;
	top: 0;
	right: -250px;
	width: 250px;
	height: 100%;
	background-color: #1c1c28;
	color: #fff;
	z-index: 1000;
	padding: 20px;
	transition: right 0.3s ease;
}

.sidebar.open {
	right: 0;
}

.sidebar-header {
	position: absolute;
	left: 0;
	width: 90%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: space-between;
	gap: 10px;
	border-bottom: 1px solid #ccc;
	margin-bottom: 1em;
	padding-left: 1em;
	padding-right: 2em;
	padding-bottom: 1em;
}

.tot-title-sidebar {
	font-weight: bold;
	font-size: large;
}

.close-menu {
	cursor: pointer;
}

.sidebar-content {
	margin-top: 4em;
}

.sidebar-content span {
	display: flex;
	align-items: center;
	padding-bottom: 10px;
	gap: 10px;
}

.overlay {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background-color: rgba(0, 0, 0, 0.5);
	z-index: 999;
}

.header-right {
	display: flex;
	align-items: center;
	justify-content: flex-end;
}

.today {
	margin-right: 10px;
}

.sidebar-footer {
	position: absolute;
	bottom: 60px;
	left: 0; /* Set left to 0 to center-align the content */
	width: 100%; /* Ensure the footer takes the full width of the sidebar */
	display: flex;
	flex-direction: column;
	align-items: center; /* Center-align the text horizontally */
	gap: 10px;
	border-top: 1px solid #ccc; /* Keep the top border */
	padding-top: 10px; /* Add padding above the first item */
}

.footer-link {
	text-decoration: none;
	text-align: left;
}

.sidebar-logo {
	display: flex;
	align-items: center;
	gap: 5px;
	text-align: left;
	font-weight: bold;
	text-transform: uppercase;
	margin-top: 1em;
}

.sidebar-logo img {
	height: 50px;
	width: auto;
}

.sidebar-content div {
	display: flex;
	align-items: center;
	position: relative;
	padding: 10px 0px;
	gap: 10px;
}

.sidebar.day {
	background-color: #f0f0f0;
	color: #000;
}

.sidebar.night {
	background-color: #1c1c28;
	color: #fff;
}

.sidebar a {
	color: inherit;
	text-decoration: none;
}

/* Desktop view */
@media (min-width: 769px) {
	.hamburger-menu,
	.no-desk {
		display: none !important;
	}
}

/* Mobile view */
@media (max-width: 768px) {
	.header-icons,
	.no-mobile {
		display: none !important;
	}
	.hamburger-menu {
		display: block;
		cursor: pointer;
		margin-left: 10px;
	}
	.header-right {
		justify-content: flex-end; /* Align items to the right */
		width: 100%;
	}

	.time-display {
		width: 100%;
		margin-top: 0em;
		text-align: center;
		padding: 0em;
		/* height: 100vh; */
	}

	.sw-time-separator {
		display: none;
	}
}

/* Mobile Footer Menu */
.mobile-menu {
	position: fixed;
	bottom: 0;
	left: 0;
	width: 100%;
	background-color: #1c1c28; /* Same as sidebar for consistency */
	color: white;
	display: none; /* Hide by default, show on mobile */
	justify-content: space-between;
	align-items: center;
	padding: 10px 0;
	z-index: 1000;
	border-top: 1px solid #333;
}

.mobile-menu .menu-item {
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	flex-grow: 1;
	text-align: center;
	font-size: 12px;
	color: #fff;
	cursor: pointer;
}

.mobile-menu .menu-item span {
	margin-top: 5px;
}

.mobile-date {
	position: fixed;
	bottom: 60px; /* Place it just above the mobile menu */
	left: 0;
	width: 100%;
	background-color: #fcc060;
	color: black;
	text-align: center; /* Center the date */
	padding: 10px 0;
	font-size: small;
	font-weight: bold;
	z-index: 1001; /* Ensure it's above other elements but below the sidebar */
	border-bottom: 1px solid #333; /* Optional: a border to separate it from the content above */
}

.mobile-sun-data {
	position: fixed;
	bottom: 95px; /* Place it just above the mobile menu */
	left: 0;
	width: 100%;
	background-color: #fceacd;
	color: black;
	text-align: center; /* Center the date */
	padding: 10px 0;
	font-size: small;
	font-weight: bold;
	z-index: 1001; /* Ensure it's above other elements but below the sidebar */
}

.mobile-sun-data {
	display: flex;
	justify-content: space-around; /* Distribute space between the sunrise and sunset sections */
	align-items: center; /* Vertically center the content */
}

.mobile-sun-data .sunrise,
.mobile-sun-data .sunset {
	display: flex;
	align-items: center; /* Vertically center icon and text inside each div */
}

.mobile-sun-data .sunrise span,
.mobile-sun-data .sunset span {
	margin-right: 8px; /* Add some space between icon and text */
}

/* Only display the mobile menu on screens smaller than 768px */
@media (max-width: 768px) {
	.mobile-menu {
		display: flex;
	}

	.location-display {
		width: 100%; /* Full width */
		justify-content: center; /* Center the content */
		text-align: center; /* Align text to the center */
	}

	.timezones {
		margin-bottom: 200px;
	}

	.left-panel,
	.right-panel {
		width: 100%;
	}

	.timezone-converter-container {
		flex-direction: column;
		gap: 1em;
	}

	.timezone-item-m {
		padding-right: 15px;
		border: 1px solid #ccc;
		margin-bottom: 10px;
		border-radius: 5px;
		background-color: #fff;
		padding: 1em 2em;
	}

	.timezone-item-m.day {
		background-color: white; /* Light background for daytime */
		color: #000; /* Dark text */
		transition: background-color 0.3s ease, color 0.3s ease;
	}

	.timezone-item-m.night {
		background-color: #1c1c28; /* Dark background for nighttime */
		color: #fff; /* Light text */
		transition: background-color 0.3s ease, color 0.3s ease;
	}

	.timezone-item-first {
		display: flex;
		justify-content: space-between;
		align-items: center;
	}

	.timezone-item-last {
		display: flex;
		align-items: center;
		gap: 10px;
	}

	.zone-time,
	.zone-offset {
		font-size: small;
	}

	.zone-name {
		font-weight: bold;
	}

	.yearly-calendar {
		padding: 0em 1em;
	}
}

/* Container for the globe and country list */
.container {
	display: flex;
	height: 100vh;
	overflow: hidden; /* Prevent content overflow */
	transition: background-color 0.3s ease, color 0.3s ease;
}

/* Globe container */
.globe-container {
	display: flex;
	justify-content: center;
	align-items: center;
	flex: 1;
	max-width: 50%; /* Limit the width to prevent overflow */
	box-sizing: border-box;
	padding: 10px;
}

/* Country list container */
.country-list-container {
	flex: 1;
	padding: 20px;
	transition: background-color 0.3s ease, color 0.3s ease;
}

/* Title for the country list */
.country-list-container h2 {
	text-align: center;
	margin-bottom: 20px;
	color: var(--text-color); /* Dynamic title color */
}

/* Country grid layout */
.country-grid {
	display: grid;
	grid-template-columns: repeat(
		auto-fill,
		minmax(200px, 1fr)
	); /* Ensure cards fill the space */
	grid-gap: 15px;
	overflow-y: auto;
	height: 100vh;
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none;
}

.country-grid::-webkit-scrollbar {
	display: none; /* For Chrome, Safari, and Opera */
}

.country-card {
	background-color: var(--card-background-color); /* Dynamic card background */
	color: var(--text-color); /* Dynamic card text color */
	padding: 15px;
	border-radius: 8px;
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
	transition: transform 0.3s ease, background-color 0.3s ease, color 0.3s ease;
	text-align: center;
	flex: 1 1 200px; /* Add this to allow flexible resizing */
	min-width: 200px; /* Minimum width for each card */
	max-height: 100px;
}

/* Add hover effect to country card */
.country-card:hover {
	background-color: var(
		--hover-card-background-color
	); /* Dynamic hover background */
}

/* Country card title */
.country-card h3 {
	margin: 0 0 10px;
	font-size: 1.2rem;
	color: var(--text-color); /* Dynamic title color */
}

/* Country card content */
.country-card p {
	margin: 0;
	color: var(--text-color); /* Dynamic content color */
}

/* Day theme */
body.day-theme {
	--background-color: #f0f0f5;
	--text-color: #000;
	--card-background-color: #ffffff;
	--hover-card-background-color: #eaeaea;
}

/* Night theme */
body.night-theme {
	--background-color: #1d1f27;
	--text-color: #f0f0f5;
	--card-background-color: #2c2c3c;
	--hover-card-background-color: #444444;
}

.globe-time {
	position: fixed;
	bottom: 1;
}

.country-list-header {
	display: flex;
	justify-content: space-between; /* Align the input to the right */
	align-items: center;
	margin-bottom: 20px;
}

.country-list-header .search-input {
	padding: 8px;
	border-radius: 4px;
	border: 1px solid #ccc;
}

.country-list-heading {
	font-weight: bold;
	font-size: x-large;
}

@media (max-width: 768px) {
	.globe-container {
		display: none;
	}

	.country-grid {
		grid-template-columns: repeat(
			auto-fill,
			minmax(200px, 2fr)
		); /* Adjust to fit mobile */
		grid-gap: 10px; /* Reduce the gap between grid items for mobile */
		padding: 0 10px; /* Add some padding to make it look cleaner on mobile */
	}

	.country-card h3 {
		font-weight: bold;
		font-size: medium;
	}

	.country-list-header {
		flex-direction: column; /* Stack items vertically */
		align-items: flex-start; /* Align items to the start */
		text-align: center;
	}

	.country-list-header div {
		margin-bottom: 10px; /* Add spacing between rows */
		width: 100%; /* Make each section take full width */
	}

	.country-list-header .search-sort-container {
		display: flex;
		justify-content: space-between;
		align-items: center;
		width: 100%; /* Search and sort should take full width */
	}

	.country-list-header .search-input {
		flex-grow: 1; /* Allow search input to grow */
		margin-right: 10px; /* Add space between search input and sort icon */
	}

	.time {
		font-size: 3rem;
		height: 80vh;
	}

	.sw-time-am-pm {
		font-size: 2rem;
	}

	.time-main {
		display: block; /* Make hours, minutes, seconds stack vertically */
	}

	.time-period {
		display: block; /* Move AM/PM below the seconds */
		margin-left: 0; /* Reset margin for mobile view */
		margin-top: -40px;
	}

	.sw-time-separator {
		display: none; /* Hide the colon separators on mobile view if needed */
	}

	.time-display {
		width: 100%;
		margin-top: 1em;
		text-align: center;
		padding-top: 1em !important;
	}

	.time h1 {
		margin-top: 0em !important;
	}
}

/* Dropdown container */
.dropdown-wrapper {
	position: relative;
	display: inline-block;
}

/* Dropdown trigger to align the arrow and text */
.dropdown-trigger {
	display: inline-flex;
	align-items: center; /* Align the arrow and text vertically */
	gap: 5px; /* Add some space between text and arrow */
	cursor: pointer;
}

/* Dropdown menu styles */
.dropdown-menu {
	display: block;
	position: absolute;
	background-color: #1c1c28; /* Background color for dropdown */
	box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Add shadow for depth */
	padding: 10px 0;
	border-radius: 8px;
	min-width: 160px; /* Ensure minimum width */
	z-index: 1000; /* Make sure it appears above other elements */
}

/* Dropdown items */
.dropdown-item {
	padding: 10px 20px;
	color: white; /* Dropdown text color */
	cursor: pointer;
	text-align: left;
	white-space: nowrap; /* Prevent text from wrapping */
	font-size: 14px;
}

/* Hover effect for dropdown items */
.dropdown-item:hover {
	background-color: #444; /* Highlight background on hover */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.body,
body,
html {
	padding-bottom: 2em !important;
	overflow-y: scroll; /* Enable scrolling */
	scrollbar-width: none; /* For Firefox */
	-ms-overflow-style: none; /* Internet Explorer 10+ */
}

/* Hide scrollbar for Chrome, Safari, and Opera */
.body::-webkit-scrollbar,
body::-webkit-scrollbar,
html::-webkit-scrollbar {
	display: none;
}

@media (max-width: 768px) {
	.timer,
	.timer .time,
	.alert-section-container .time {
		height: auto !important;
	}

	.stopwatch .sw-time-seconds {
		font-size: 3rem;
	}

	.alert-section {
		margin-bottom: 2em;
	}
}
